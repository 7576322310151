import React from "react"
import { Router, Redirect } from "@gatsbyjs/reach-router"
import { Billing } from "./features/Billing"
import {
  ProtectedRoute,
  AuthRedirect,
  Logout,
  AuthClose,
} from "./features/Auth"
import {
  SiteConfiguration,
  SiteCreationTypePicker,
  ProvisionConfiguration,
  DeployNowWizard,
} from "./features/CreateSite"
import { BuildsListPage } from "./features/BuildsList"
import { PullRequestBuildsPage } from "./features/PullRequestBuildsList"
import { SitesListView } from "./features/SitesListView"
import { SiteInformationView } from "./features/SiteInformationView"
import { OrganizationDetailsView } from "./features/OrganizationDetailsView"
import { UserSettings } from "./features/UserAccount"
import { Login } from "./features/User/Login"
import { Signup } from "./features/User/Signup"
import { SingleSignOn } from "./features/User/SingleSignOn"
import { Welcome } from "./features/User/Welcome"
import { CreateOrganization } from "./features/CreateOrganization"
import { AcceptInvitation } from "./features/AcceptInvitation"
import {
  Maintenance,
  NotFound,
  OrganizationIdle,
  OrganizationOverage,
} from "./features/ServiceRoutes"
import { SitePreviewAuth } from "./features/SitePreview"
import { BuildLogsPage, BuildDetailsPage } from "./features/BuildDetails"
import { BuildFunctionPage } from "./features/Functions"
import { Transfer } from "./features/Site"

// data-layer routes
import {
  DataLayerRoot,
  DataLayerDetails,
  SourceEventDetails,
  SourceEventStructuredLogs,
  SourceEventRawLogs,
} from "@gatsbyjs/theme-data-layer"

// Needed for maintaining / redirecting URL bookmarks users may have
function CreateSiteRedirect({ organizationId }) {
  return (
    <Redirect
      to={`/dashboard/organization/${organizationId}/sites/create`}
      noThrow
    />
  )
}

function Dashboard() {
  return (
    <Router>
      <Maintenance path="/dashboard/maintenance" />

      <OrganizationOverage path="/dashboard/overage" />

      <OrganizationIdle path="/dashboard/idle" />

      <Redirect from="/dashboard/" to="/dashboard/login" noThrow />

      <Signup path="/dashboard/signup" />

      <Login path="/dashboard/login" />

      <SingleSignOn path="/dashboard/sso" />

      <AuthClose path="/dashboard/close" />

      <SitePreviewAuth path="/dashboard/previews/login" />

      <AuthRedirect path="/dashboard/redirect" />

      <Logout path="/dashboard/logout" />

      <ProtectedRoute path="/dashboard/welcome" component={Welcome} />

      <ProtectedRoute
        component={AcceptInvitation}
        path="/dashboard/invitations/:invitationId"
      />

      <ProtectedRoute
        component={BuildLogsPage}
        path="/dashboard/builds/:buildId/logs"
      />

      <ProtectedRoute component={DeployNowWizard} path="/dashboard/deploynow" />

      <ProtectedRoute
        path="/dashboard/organization/create"
        component={CreateOrganization}
      />

      {/* START Site creation paths */}
      <ProtectedRoute
        component={SiteCreationTypePicker}
        path="/dashboard/organization/:organizationId/sites/create"
      />

      <ProtectedRoute
        component={SiteConfiguration}
        path="/dashboard/organization/:organizationId/sites/import"
      />

      <ProtectedRoute
        component={ProvisionConfiguration}
        path="/dashboard/organization/:organizationId/sites/provision"
      />

      {/* fallback route to provide parity between URLs of experiment and baseline */}
      <ProtectedRoute
        component={CreateSiteRedirect}
        path="/dashboard/organization/:organizationId/sites/import/*"
      />

      <ProtectedRoute
        component={CreateSiteRedirect}
        path="/dashboard/organization/:organizationId/sites/provision/*"
      />
      {/* END Site creation paths */}

      {/* Quick site from a url*/}
      <ProtectedRoute
        component={DeployNowWizard}
        path="/dashboard/organization/:organizationId/sites/deploynow/:siteId/integrations"
      />

      <ProtectedRoute
        component={DeployNowWizard}
        path="/dashboard/organization/:organizationId/sites/deploynow/:siteId/setup"
      />
      {/* END Site creation paths */}

      <ProtectedRoute
        component={BuildsListPage}
        path="/dashboard/:organizationId/sites/:siteId/branches/:branch/builds"
      />

      <ProtectedRoute
        component={PullRequestBuildsPage}
        path="/dashboard/:organizationId/sites/:siteId/pull-requests/:pullRequestId/builds"
      />

      <ProtectedRoute
        component={BuildDetailsPage}
        path="/dashboard/:organizationId/sites/:siteId/builds/:buildId/details"
      />

      <ProtectedRoute
        component={BuildFunctionPage}
        path="/dashboard/:organizationId/sites/:siteId/builds/:buildId/functions/:functionId/details"
      />

      <ProtectedRoute component={SitesListView} path="/dashboard/sites" />

      <ProtectedRoute component={UserSettings} path="/dashboard/account" />

      {/* Nested Reach Router for data-layer */}
      <ProtectedRoute
        component={SiteInformationView}
        path="/dashboard/:organizationId/sites/:siteId"
      >
        <DataLayerRoot path="data-layer">
          <DataLayerDetails path="/" />
          <SourceEventDetails path="/source-event/:sourceEventId">
            <SourceEventStructuredLogs path="/" />
            <SourceEventRawLogs path="raw" />
          </SourceEventDetails>
        </DataLayerRoot>
      </ProtectedRoute>
      <ProtectedRoute
        component={SiteInformationView}
        path="/dashboard/:organizationId/sites/:siteId/*"
      />

      <ProtectedRoute
        component={({ siteId, organizationId }) => (
          <Redirect
            to={`/dashboard/${organizationId}/sites/${siteId}/previews`}
            noThrow
          />
        )}
        path="/dashboard/:organizationId/sites/previews/:siteId"
      />

      <ProtectedRoute
        component={Billing}
        path="/dashboard/pricing/:organizationId"
      />

      <ProtectedRoute
        component={OrganizationDetailsView}
        path="/dashboard/organization/:organizationId/*"
      />

      <ProtectedRoute
        component={Transfer}
        path="/dashboard/site-transfer/:invitationId/*"
      />

      <NotFound path="/dashboard/*" />
    </Router>
  )
}

export default Dashboard
